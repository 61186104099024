/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================





 =========================================================

* .
*/

export default {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
};
