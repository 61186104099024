/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================





 =========================================================

* .
*/

/**
  The pxToRem() function helps you to convert a px unit into a rem unit, 
 */

function pxToRem(number, baseNumber = 16) {
  return `${number / baseNumber}rem`;
}

export default pxToRem;
