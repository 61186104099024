/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================
*
*/

// Material Kit 2 React pages
import AboutUs from "pages/LandingPages/AboutUs";

export default function AboutUsPage() {
  return <AboutUs />;
}
