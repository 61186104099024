/* eslint-disable */
/*
=========================================================
* Material Kit 2 React - v2.1.0

*
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Information from "pages/Presentation/sections/Information";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/banner2.jpg";
import FeaturesOne from "layouts/sections/page-sections/featuers/components/FeaturesOne";
import MKButton from "components/MKButton";

function HomePage() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                action={{
                    type: "external",
                    route: "/",
                    label: "Request A Demo",
                    color: "info",
                }}
                sticky
            />
            <MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
                        <MKTypography
                            variant="h1"
                            color="white"
                            mt={-6}
                            mb={1}
                            sx={({ breakpoints, typography: { size } }) => ({
                                color: "#17fffe",
                                textAlign: 'center',
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            Enterprise Battery Intelligence™ Is Key to Your Company’s Future
                            <br />
                            <MKButton sx={{ backgroundColor: "#17fffe" }} size="large">
                                Request A Demo
                            </MKButton>
                            {/* <Button variant="outlined" size="large" sx={{color:"#17fffe",}}>
                            Request A Demo
                           </Button> */}
                        </MKTypography>
                        <MKTypography
                            variant="body1"
                            color="white"
                            textAlign="center"
                            px={{ xs: 6, lg: 12 }}
                            mt={1}
                        >
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>

            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                    backdropFilter: "saturate(200%) blur(30px)",
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justifyContent="center"
                    sx={{ mx: "auto", textAlign: "center" }}
                >
                    <MKTypography variant="h1" sx={{ marginTop: 7 }}>Benifits</MKTypography>
                    {/* <Counters /> */}
                    {/* <DiagonalComponent imageUrl={bgImage} text="Ashok" /> */}
                </Grid>

                <>
                    <FeaturesOne />
                </>

                <Information />
                {/* <DesignBlocks /> */}
                {/* <Pages /> */}
                <Container sx={{ mt: 6 }}>
                    <BuiltByDevelopers />
                </Container>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <FilledInfoCard
                                variant="gradient"
                                color="info"
                                icon="flag"
                                title="Getting Started"
                                description="Check the possible ways of working with our product and the necessary files for building your own project."
                                action={{
                                    type: "external",
                                    route: "/",
                                    label: "Let's start",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FilledInfoCard
                                color="info"
                                icon="precision_manufacturing"
                                title="Plugins"
                                description="Get inspiration and have an overview about the plugins that we used to create the Material Kit."
                                action={{
                                    type: "external",
                                    route: "/",
                                    label: "Read more",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FilledInfoCard
                                color="info"
                                icon="apps"
                                title="Components"
                                description="Material Kit is giving you a lot of pre-made components, that will help you to build UI's faster."
                                action={{
                                    type: "external",
                                    route: "/",
                                    label: "Read more",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <Testimonials />
                <Download />
                <MKBox pt={18} pb={6}>
                    <Container>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                                    Thank you for your support!
                                </MKTypography>
                                <MKTypography variant="body1" color="text">
                                    We deliver the best web products
                                </MKTypography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={5}
                                my={{ xs: 5, lg: "auto" }}
                                mr={{ xs: 0, lg: "auto" }}
                                sx={{ textAlign: { xs: "center", lg: "right" } }}
                            >
                                <MKSocialButton
                                    component="a"
                                    href="/"
                                    target="_blank"
                                    color="twitter"
                                    sx={{ mr: 1 }}
                                >
                                    <i className="fab fa-twitter" />
                                    &nbsp;Tweet
                                </MKSocialButton>
                                <MKSocialButton
                                    component="a"
                                    href="/"
                                    target="_blank"
                                    color="facebook"
                                    sx={{ mr: 1 }}
                                >
                                    <i className="fab fa-facebook" />
                                    &nbsp;Share
                                </MKSocialButton>
                                <MKSocialButton
                                    component="a"
                                    href="/"
                                    target="_blank"
                                    color="pinterest"
                                >
                                    <i className="fab fa-pinterest" />
                                    &nbsp;Pin it
                                </MKSocialButton>
                            </Grid>
                        </Grid>
                    </Container>
                </MKBox>
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default HomePage;
