/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================





 =========================================================

* .
*/

// Material Kit 2 React Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

export default {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
      padding: `${pxToRem(8)} ${pxToRem(24)} ${pxToRem(24)}`,
    },
  },
};
