/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================





 =========================================================

* .
*/

export default {
  defaultProps: {
    underline: "none",
    color: "inherit",
  },
};
