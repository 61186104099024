/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================





 =========================================================

* .
*/

export default {
  defaultProps: {
    disableGutters: true,
  },

  styleOverrides: {
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};
